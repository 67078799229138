/* Load this script using conditional IE comments if you need to support IE 7 and IE 6. */

window.onload = function() {
	function addIcon(el, entity) {
		var html = el.innerHTML;
		el.innerHTML = '<span style="font-family: \'ElegantIcons\'">' + entity + '</span>' + html;
	}
	var icons = {
			'arrow_up' : '&#x21;',
			'arrow_down' : '&#x22;',
			'arrow_left' : '&#x23;',
			'arrow_right' : '&#x24;',
			'arrow_left-up' : '&#x25;',
			'arrow_right-up' : '&#x26;',
			'arrow_right-down' : '&#x27;',
			'arrow_left-down' : '&#x28;',
			'arrow-up-down' : '&#x29;',
			'arrow_up-down_alt' : '&#x2a;',
			'arrow_left-right_alt' : '&#x2b;',
			'arrow_left-right' : '&#x2c;',
			'arrow_expand_alt2' : '&#x2d;',
			'arrow_expand_alt' : '&#x2e;',
			'arrow_condense' : '&#x2f;',
			'arrow_expand' : '&#x30;',
			'arrow_move' : '&#x31;',
			'arrow_carrot-up' : '&#x32;',
			'arrow_carrot-down' : '&#x33;',
			'arrow_carrot-left' : '&#x34;',
			'arrow_carrot-right' : '&#x35;',
			'arrow_carrot-2up' : '&#x36;',
			'arrow_carrot-2down' : '&#x37;',
			'arrow_carrot-2left' : '&#x38;',
			'arrow_carrot-2right' : '&#x39;',
			'arrow_carrot-up_alt2' : '&#x3a;',
			'arrow_carrot-down_alt2' : '&#x3b;',
			'arrow_carrot-left_alt2' : '&#x3c;',
			'arrow_carrot-right_alt2' : '&#x3d;',
			'arrow_carrot-2up_alt2' : '&#x3e;',
			'arrow_carrot-2down_alt2' : '&#x3f;',
			'arrow_carrot-2left_alt2' : '&#x40;',
			'arrow_carrot-2right_alt2' : '&#x41;',
			'arrow_triangle-up' : '&#x42;',
			'arrow_triangle-down' : '&#x43;',
			'arrow_triangle-left' : '&#x44;',
			'arrow_triangle-right' : '&#x45;',
			'arrow_triangle-up_alt2' : '&#x46;',
			'arrow_triangle-down_alt2' : '&#x47;',
			'arrow_triangle-left_alt2' : '&#x48;',
			'arrow_triangle-right_alt2' : '&#x49;',
			'arrow_back' : '&#x4a;',
			'icon_minus-06' : '&#x4b;',
			'icon_plus' : '&#x4c;',
			'icon_close' : '&#x4d;',
			'icon_check' : '&#x4e;',
			'icon_minus_alt2' : '&#x4f;',
			'icon_plus_alt2' : '&#x50;',
			'icon_close_alt2' : '&#x51;',
			'icon_check_alt2' : '&#x52;',
			'icon_zoom-out_alt' : '&#x53;',
			'icon_zoom-in_alt' : '&#x54;',
			'icon_search' : '&#x55;',
			'icon_box-empty' : '&#x56;',
			'icon_box-selected' : '&#x57;',
			'icon_minus-box' : '&#x58;',
			'icon_plus-box' : '&#x59;',
			'icon_box-checked' : '&#x5a;',
			'icon_circle-empty' : '&#x5b;',
			'icon_circle-slelected' : '&#x5c;',
			'icon_stop_alt2' : '&#x5d;',
			'icon_stop' : '&#x5e;',
			'icon_pause_alt2' : '&#x5f;',
			'icon_pause' : '&#x60;',
			'icon_menu' : '&#x61;',
			'icon_menu-square_alt2' : '&#x62;',
			'icon_menu-circle_alt2' : '&#x63;',
			'icon_ul' : '&#x64;',
			'icon_ol' : '&#x65;',
			'icon_adjust-horiz' : '&#x66;',
			'icon_adjust-vert' : '&#x67;',
			'icon_document_alt' : '&#x68;',
			'icon_documents_alt' : '&#x69;',
			'icon_pencil' : '&#x6a;',
			'icon_pencil-edit_alt' : '&#x6b;',
			'icon_pencil-edit' : '&#x6c;',
			'icon_folder-alt' : '&#x6d;',
			'icon_folder-open_alt' : '&#x6e;',
			'icon_folder-add_alt' : '&#x6f;',
			'icon_info_alt' : '&#x70;',
			'icon_error-oct_alt' : '&#x71;',
			'icon_error-circle_alt' : '&#x72;',
			'icon_error-triangle_alt' : '&#x73;',
			'icon_question_alt2' : '&#x74;',
			'icon_question' : '&#x75;',
			'icon_comment_alt' : '&#x76;',
			'icon_chat_alt' : '&#x77;',
			'icon_vol-mute_alt' : '&#x78;',
			'icon_volume-low_alt' : '&#x79;',
			'icon_volume-high_alt' : '&#x7a;',
			'icon_quotations' : '&#x7b;',
			'icon_quotations_alt2' : '&#x7c;',
			'icon_clock_alt' : '&#x7d;',
			'icon_lock_alt' : '&#x7e;',
			'icon_lock-open_alt' : '&#xe000;',
			'icon_key_alt' : '&#xe001;',
			'icon_cloud_alt' : '&#xe002;',
			'icon_cloud-upload_alt' : '&#xe003;',
			'icon_cloud-download_alt' : '&#xe004;',
			'icon_image' : '&#xe005;',
			'icon_images' : '&#xe006;',
			'icon_lightbulb_alt' : '&#xe007;',
			'icon_gift_alt' : '&#xe008;',
			'icon_house_alt' : '&#xe009;',
			'icon_genius' : '&#xe00a;',
			'icon_mobile' : '&#xe00b;',
			'icon_tablet' : '&#xe00c;',
			'icon_laptop' : '&#xe00d;',
			'icon_desktop' : '&#xe00e;',
			'icon_camera_alt' : '&#xe00f;',
			'icon_mail_alt' : '&#xe010;',
			'icon_cone_alt' : '&#xe011;',
			'icon_ribbon_alt' : '&#xe012;',
			'icon_bag_alt' : '&#xe013;',
			'icon_creditcard' : '&#xe014;',
			'icon_cart_alt' : '&#xe015;',
			'icon_paperclip' : '&#xe016;',
			'icon_tag_alt' : '&#xe017;',
			'icon_tags_alt' : '&#xe018;',
			'icon_trash_alt' : '&#xe019;',
			'icon_cursor_alt' : '&#xe01a;',
			'icon_mic_alt' : '&#xe01b;',
			'icon_compass_alt' : '&#xe01c;',
			'icon_pin_alt' : '&#xe01d;',
			'icon_pushpin_alt' : '&#xe01e;',
			'icon_map_alt' : '&#xe01f;',
			'icon_drawer_alt' : '&#xe020;',
			'icon_toolbox_alt' : '&#xe021;',
			'icon_book_alt' : '&#xe022;',
			'icon_calendar' : '&#xe023;',
			'icon_film' : '&#xe024;',
			'icon_table' : '&#xe025;',
			'icon_contacts_alt' : '&#xe026;',
			'icon_headphones' : '&#xe027;',
			'icon_lifesaver' : '&#xe028;',
			'icon_piechart' : '&#xe029;',
			'icon_refresh' : '&#xe02a;',
			'icon_link_alt' : '&#xe02b;',
			'icon_link' : '&#xe02c;',
			'icon_loading' : '&#xe02d;',
			'icon_blocked' : '&#xe02e;',
			'icon_archive_alt' : '&#xe02f;',
			'icon_heart_alt' : '&#xe030;',
			'icon_star_alt' : '&#xe031;',
			'icon_star-half_alt' : '&#xe032;',
			'icon_star' : '&#xe033;',
			'icon_star-half' : '&#xe034;',
			'icon_tools' : '&#xe035;',
			'icon_tool' : '&#xe036;',
			'icon_cog' : '&#xe037;',
			'icon_cogs' : '&#xe038;',
			'arrow_up_alt' : '&#xe039;',
			'arrow_down_alt' : '&#xe03a;',
			'arrow_left_alt' : '&#xe03b;',
			'arrow_right_alt' : '&#xe03c;',
			'arrow_left-up_alt' : '&#xe03d;',
			'arrow_right-up_alt' : '&#xe03e;',
			'arrow_right-down_alt' : '&#xe03f;',
			'arrow_left-down_alt' : '&#xe040;',
			'arrow_condense_alt' : '&#xe041;',
			'arrow_expand_alt3' : '&#xe042;',
			'arrow_carrot_up_alt' : '&#xe043;',
			'arrow_carrot-down_alt' : '&#xe044;',
			'arrow_carrot-left_alt' : '&#xe045;',
			'arrow_carrot-right_alt' : '&#xe046;',
			'arrow_carrot-2up_alt' : '&#xe047;',
			'arrow_carrot-2dwnn_alt' : '&#xe048;',
			'arrow_carrot-2left_alt' : '&#xe049;',
			'arrow_carrot-2right_alt' : '&#xe04a;',
			'arrow_triangle-up_alt' : '&#xe04b;',
			'arrow_triangle-down_alt' : '&#xe04c;',
			'arrow_triangle-left_alt' : '&#xe04d;',
			'arrow_triangle-right_alt' : '&#xe04e;',
			'icon_minus_alt' : '&#xe04f;',
			'icon_plus_alt' : '&#xe050;',
			'icon_close_alt' : '&#xe051;',
			'icon_check_alt' : '&#xe052;',
			'icon_zoom-out' : '&#xe053;',
			'icon_zoom-in' : '&#xe054;',
			'icon_stop_alt' : '&#xe055;',
			'icon_menu-square_alt' : '&#xe056;',
			'icon_menu-circle_alt' : '&#xe057;',
			'icon_document' : '&#xe058;',
			'icon_documents' : '&#xe059;',
			'icon_pencil_alt' : '&#xe05a;',
			'icon_folder' : '&#xe05b;',
			'icon_folder-open' : '&#xe05c;',
			'icon_folder-add' : '&#xe05d;',
			'icon_folder_upload' : '&#xe05e;',
			'icon_folder_download' : '&#xe05f;',
			'icon_info' : '&#xe060;',
			'icon_error-circle' : '&#xe061;',
			'icon_error-oct' : '&#xe062;',
			'icon_error-triangle' : '&#xe063;',
			'icon_question_alt' : '&#xe064;',
			'icon_comment' : '&#xe065;',
			'icon_chat' : '&#xe066;',
			'icon_vol-mute' : '&#xe067;',
			'icon_volume-low' : '&#xe068;',
			'icon_volume-high' : '&#xe069;',
			'icon_quotations_alt' : '&#xe06a;',
			'icon_clock' : '&#xe06b;',
			'icon_lock' : '&#xe06c;',
			'icon_lock-open' : '&#xe06d;',
			'icon_key' : '&#xe06e;',
			'icon_cloud' : '&#xe06f;',
			'icon_cloud-upload' : '&#xe070;',
			'icon_cloud-download' : '&#xe071;',
			'icon_lightbulb' : '&#xe072;',
			'icon_gift' : '&#xe073;',
			'icon_house' : '&#xe074;',
			'icon_camera' : '&#xe075;',
			'icon_mail' : '&#xe076;',
			'icon_cone' : '&#xe077;',
			'icon_ribbon' : '&#xe078;',
			'icon_bag' : '&#xe079;',
			'icon_cart' : '&#xe07a;',
			'icon_tag' : '&#xe07b;',
			'icon_tags' : '&#xe07c;',
			'icon_trash' : '&#xe07d;',
			'icon_cursor' : '&#xe07e;',
			'icon_mic' : '&#xe07f;',
			'icon_compass' : '&#xe080;',
			'icon_pin' : '&#xe081;',
			'icon_pushpin' : '&#xe082;',
			'icon_map' : '&#xe083;',
			'icon_drawer' : '&#xe084;',
			'icon_toolbox' : '&#xe085;',
			'icon_book' : '&#xe086;',
			'icon_contacts' : '&#xe087;',
			'icon_archive' : '&#xe088;',
			'icon_heart' : '&#xe089;',
			'icon_profile' : '&#xe08a;',
			'icon_group' : '&#xe08b;',
			'icon_grid-2x2' : '&#xe08c;',
			'icon_grid-3x3' : '&#xe08d;',
			'icon_music' : '&#xe08e;',
			'icon_pause_alt' : '&#xe08f;',
			'icon_phone' : '&#xe090;',
			'icon_upload' : '&#xe091;',
			'icon_download' : '&#xe092;',
			'social_facebook' : '&#xe093;',
			'social_twitter' : '&#xe094;',
			'social_pinterest' : '&#xe095;',
			'social_googleplus' : '&#xe096;',
			'social_tumblr' : '&#xe097;',
			'social_tumbleupon' : '&#xe098;',
			'social_wordpress' : '&#xe099;',
			'social_instagram' : '&#xe09a;',
			'social_dribbble' : '&#xe09b;',
			'social_vimeo' : '&#xe09c;',
			'social_linkedin' : '&#xe09d;',
			'social_rss' : '&#xe09e;',
			'social_deviantart' : '&#xe09f;',
			'social_share' : '&#xe0a0;',
			'social_myspace' : '&#xe0a1;',
			'social_skype' : '&#xe0a2;',
			'social_youtube' : '&#xe0a3;',
			'social_picassa' : '&#xe0a4;',
			'social_googledrive' : '&#xe0a5;',
			'social_flickr' : '&#xe0a6;',
			'social_blogger' : '&#xe0a7;',
			'social_spotify' : '&#xe0a8;',
			'social_delicious' : '&#xe0a9;',
			'social_facebook_circle' : '&#xe0aa;',
			'social_twitter_circle' : '&#xe0ab;',
			'social_pinterest_circle' : '&#xe0ac;',
			'social_googleplus_circle' : '&#xe0ad;',
			'social_tumblr_circle' : '&#xe0ae;',
			'social_stumbleupon_circle' : '&#xe0af;',
			'social_wordpress_circle' : '&#xe0b0;',
			'social_instagram_circle' : '&#xe0b1;',
			'social_dribbble_circle' : '&#xe0b2;',
			'social_vimeo_circle' : '&#xe0b3;',
			'social_linkedin_circle' : '&#xe0b4;',
			'social_rss_circle' : '&#xe0b5;',
			'social_deviantart_circle' : '&#xe0b6;',
			'social_share_circle' : '&#xe0b7;',
			'social_myspace_circle' : '&#xe0b8;',
			'social_skype_circle' : '&#xe0b9;',
			'social_youtube_circle' : '&#xe0ba;',
			'social_picassa_circle' : '&#xe0bb;',
			'social_googledrive_alt2' : '&#xe0bc;',
			'social_flickr_circle' : '&#xe0bd;',
			'social_blogger_circle' : '&#xe0be;',
			'social_spotify_circle' : '&#xe0bf;',
			'social_delicious_circle' : '&#xe0c0;',
			'social_facebook_square' : '&#xe0c1;',
			'social_twitter_square' : '&#xe0c2;',
			'social_pinterest_square' : '&#xe0c3;',
			'social_googleplus_square' : '&#xe0c4;',
			'social_tumblr_square' : '&#xe0c5;',
			'social_stumbleupon_square' : '&#xe0c6;',
			'social_wordpress_square' : '&#xe0c7;',
			'social_instagram_square' : '&#xe0c8;',
			'social_dribbble_square' : '&#xe0c9;',
			'social_vimeo_square' : '&#xe0ca;',
			'social_linkedin_square' : '&#xe0cb;',
			'social_rss_square' : '&#xe0cc;',
			'social_deviantart_square' : '&#xe0cd;',
			'social_share_square' : '&#xe0ce;',
			'social_myspace_square' : '&#xe0cf;',
			'social_skype_square' : '&#xe0d0;',
			'social_youtube_square' : '&#xe0d1;',
			'social_picassa_square' : '&#xe0d2;',
			'social_googledrive_square' : '&#xe0d3;',
			'social_flickr_square' : '&#xe0d4;',
			'social_blogger_square' : '&#xe0d5;',
			'social_spotify_square' : '&#xe0d6;',
			'social_delicious_square' : '&#xe0d7;',
			'icon_printer' : '&#xe103;',
			'icon_calulator' : '&#xe0ee;',
			'icon_building' : '&#xe0ef;',
			'icon_floppy' : '&#xe0e8;',
			'icon_drive' : '&#xe0ea;',
			'icon_search-2' : '&#xe101;',
			'icon_id' : '&#xe107;',
			'icon_id-2' : '&#xe108;',
			'icon_puzzle' : '&#xe102;',
			'icon_like' : '&#xe106;',
			'icon_dislike' : '&#xe0eb;',
			'icon_mug' : '&#xe105;',
			'icon_currency' : '&#xe0ed;',
			'icon_wallet' : '&#xe100;',
			'icon_pens' : '&#xe104;',
			'icon_easel' : '&#xe0e9;',
			'icon_flowchart' : '&#xe109;',
			'icon_datareport' : '&#xe0ec;',
			'icon_briefcase' : '&#xe0fe;',
			'icon_shield' : '&#xe0f6;',
			'icon_percent' : '&#xe0fb;',
			'icon_globe' : '&#xe0e2;',
			'icon_globe-2' : '&#xe0e3;',
			'icon_target' : '&#xe0f5;',
			'icon_hourglass' : '&#xe0e1;',
			'icon_balance' : '&#xe0ff;',
			'icon_rook' : '&#xe0f8;',
			'icon_printer-alt' : '&#xe0fa;',
			'icon_calculator_alt' : '&#xe0e7;',
			'icon_building_alt' : '&#xe0fd;',
			'icon_floppy_alt' : '&#xe0e4;',
			'icon_drive_alt' : '&#xe0e5;',
			'icon_search_alt' : '&#xe0f7;',
			'icon_id_alt' : '&#xe0e0;',
			'icon_id-2_alt' : '&#xe0fc;',
			'icon_puzzle_alt' : '&#xe0f9;',
			'icon_like_alt' : '&#xe0dd;',
			'icon_dislike_alt' : '&#xe0f1;',
			'icon_mug_alt' : '&#xe0dc;',
			'icon_currency_alt' : '&#xe0f3;',
			'icon_wallet_alt' : '&#xe0d8;',
			'icon_pens_alt' : '&#xe0db;',
			'icon_easel_alt' : '&#xe0f0;',
			'icon_flowchart_alt' : '&#xe0df;',
			'icon_datareport_alt' : '&#xe0f2;',
			'icon_briefcase_alt' : '&#xe0f4;',
			'icon_shield_alt' : '&#xe0d9;',
			'icon_percent_alt' : '&#xe0da;',
			'icon_globe_alt' : '&#xe0de;',
			'icon_clipboard' : '&#xe0e6;'
		},
		els = document.getElementsByTagName('*'),
		i, attr, c, el;
	for (i = 0; ; i += 1) {
		el = els[i];
		if(!el) {
			break;
		}
		attr = el.getAttribute('data-icon');
		if (attr) {
			addIcon(el, attr);
		}
		c = el.className;
		c = c.match(/[^\s'"]+/);
		if (c && icons[c[0]]) {
			addIcon(el, icons[c[0]]);
		}
	}
};